import { stateInitial, stateLoading, stateLoaded, stateError } from "./util";
import { getUserAppSettings } from "@/services/settingsService.js";

const initialState = {
  appSettings: {
    settings: [],
    state: stateInitial
  },
  shouldFetchCustomerLogo: false
};

const mutations = {
  updateAppSettingsState(state, appSettingsState) {
    state.appSettings.state = appSettingsState;
  },
  updateAppSettings(state, settings) {
    state.appSettings = { settings, state: stateLoaded };
  },
  toggleShouldFetchCustomerLogo(state) {
    state.shouldFetchCustomerLogo = !state.shouldFetchCustomerLogo;
  }
};

const actions = {
  async loadAppSettings(context) {
    try {
      context.commit("updateAppSettingsState", stateLoading);
      const response = await getUserAppSettings();
      const settings = response.data;
      if (settings && settings.length > 0) {
        context.commit("updateAppSettings", response.data);
      }
    } catch (e) {
      context.commit("updateAppSettingsState", stateError);
      console.error(e);
    }
  }
};

const getters = {
  appSettings: state => state.appSettings,
  shouldFetchCustomerLogo: state => state.shouldFetchCustomerLogo
};

export const settingsModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
