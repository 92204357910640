import axios from "axios";
import { QS } from "@/services/util.js";
import { apiRoot, warehouseRoot } from "@/preferences";

export function getCompanyInfo() {
  return axios.get(`${apiRoot}/chatgpt/companyinfo/retrieve/`);
}

export function editCompanyInfo(payload) {
  return axios.patch(`${apiRoot}/chatgpt/companyinfo/edit/`, payload);
}

export function generateChatgptAgreementPdf() {
  return axios.post(`${apiRoot}/chatgpt/pdf/generate/`);
}
export function getChatgptChangelog(query) {
  return axios.get(`${apiRoot}/chatgpt/changelog/?${QS(query)}`);
}

export function getChatgptAgreementPdf() {
  return axios({
    url: `${apiRoot}/chatgpt/pdf/download/`,
    method: "GET",
    responseType: "blob"
  });
}

export function downloadChatgptSettingsAgreementPdf(query) {
  return axios({
    url: `${warehouseRoot}/chatgpt/agreement/download?${QS(query)}`,
    method: "GET",
    responseType: "blob"
  });
}

export function getGptTokensUsageWarning() {
  return axios.get(`${warehouseRoot}/recommendation/warning`);
}

export function retrieveGptTokens() {
  return axios.get(`${warehouseRoot}/recommendation/tokens`);
}
