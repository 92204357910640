import axios from "axios";
import { apiRoot } from "@/preferences";

export function getForecastableCategories() {
  return axios.get(`${apiRoot}/forecasting/categories/`);
}

export function getForecastReportsForCategory(id) {
  return axios.get(`${apiRoot}/forecasting/${id}/reports/`);
}

export function getForecastableSources(categoryId) {
  return axios.get(`${apiRoot}/forecasting/${categoryId}/sources/`);
}

export function getForecastById(id) {
  return axios.get(`${apiRoot}/forecasting/${id}/`);
}

export function createNewForecast(payload) {
  return axios.post(`${apiRoot}/forecasting/`, payload);
}

export function updateForecast(id, payload) {
  return axios.patch(`${apiRoot}/forecasting/${id}/`, payload);
}

export function deleteForecast(id) {
  return axios.delete(`${apiRoot}/forecasting/${id}/`);
}
