import axios from "axios";
import { apiRoot, goApiRoot } from "../preferences";
import { QS } from "@/services/util.js";

export function getObjectivesOverview(startDate, endDate) {
  return axios.get(`${goApiRoot}/okr/objectives/?${QS({ startDate, endDate })}`);
}

export function createMilestone(payload) {
  return axios.post(`${apiRoot}/okr/milestone/create/`, payload);
}
//payload = {isDone: boolean}
export function patchMilestone(payload) {
  return axios.patch(`${apiRoot}/okr/milestone/update/${payload.id}/`, payload);
}

export function deleteMilestone(id) {
  return axios.delete(`${apiRoot}/okr/milestone/update/${id}/`);
}

export function getStatusList() {
  return axios.get(`${apiRoot}/okr/status/`);
}

export function patchStatusList(data) {
  return axios.patch(`${apiRoot}/okr/status/`, data);
}

export function getObjectivesDetails(query) {
  return axios.get(`${goApiRoot}/okr/objectives/details?${QS(query)}`);
}

export function postObjective(objective) {
  return axios.post(`${apiRoot}/okr/objectives/create/`, objective);
}

export function copyObjective(sourceId, objective, query) {
  return axios.post(`${apiRoot}/okr/objectives/${sourceId}/copy/?${QS(query)}`, objective);
}
export function moveObjective(sourceId, objective, query) {
  return axios.patch(`${apiRoot}/okr/objectives/${sourceId}/move/?${QS(query)}`, objective);
}
export function batchUpdateObjectives(updates) {
  return axios.patch(`${apiRoot}/okr/objectives/update/`, updates);
}

export function postKeyResult(keyResult) {
  return axios.post(`${apiRoot}/okr/key-result/`, keyResult);
}

export function patchObjective(patch) {
  return axios.patch(`${apiRoot}/okr/objectives/${patch.id}/`, patch);
}

export function deleteObjective(id) {
  return axios.delete(`${apiRoot}/okr/objectives/${id}/`);
}

export function batchUpdateKeyResult(updates) {
  return axios.patch(`${apiRoot}/okr/key-result/`, updates);
}

export function patchKeyResult(patch) {
  return axios.patch(`${apiRoot}/okr/key-result/${patch.id}/`, patch);
}

export function deleteKeyResult(id) {
  return axios.delete(`${apiRoot}/okr/key-result/${id}/`);
}

export function getProgressStatistics(query) {
  return axios.get(`${apiRoot}/okr/progress/?${QS(query)}`);
}

export function getTeamAnalytics(query) {
  return axios.get(`${apiRoot}/okr/teamanalytics/?${QS(query)}`);
}

export function getSchedulerPlans() {
  return axios.get(`${apiRoot}/okr/scheduler/plans/`);
}

export function postSchedulerPlan(schedulerPlan) {
  return axios.post(`${apiRoot}/okr/scheduler/plans/`, schedulerPlan);
}

export function patchSchedulerPlanForYear(year, id) {
  return axios.patch(`${apiRoot}/okr/scheduler/year/${year}/update/`, {
    schedulePlan: id
  });
}

export function getSchedulerYearDetail(year) {
  return axios.get(`${apiRoot}/okr/scheduler/year/${year}/`);
}

export function getSchedulerYearCalendar(year) {
  return axios.get(`${apiRoot}/okr/scheduler/calendar/${year}/`);
}

export function postSchedulerYearPlan(year) {
  return axios.post(`${apiRoot}/okr/scheduler/year/create/`, { year: year });
}

export function postSchedule(schedule) {
  return axios.post(`${apiRoot}/okr/scheduler/schedule/create/`, schedule);
}

export function deleteSchedule(id) {
  return axios.delete(`${apiRoot}/okr/scheduler/schedule/${id}/`);
}

export function patchSchedule(schedule) {
  return axios.patch(`${apiRoot}/okr/scheduler/schedule/${schedule.id}/`, schedule);
}

export function getChangeLogData(params) {
  return axios.get(`${apiRoot}/okr/changelog/list/`, { params: params });
}

export function getAllCategories() {
  return axios.get(`${apiRoot}/okr/categories/`);
}

export function createCategory(payload) {
  return axios.post(`${apiRoot}/okr/categories/`, payload);
}

export function updateCategory(payload) {
  return axios.patch(`${apiRoot}/okr/categories/update/${payload.id}/`, payload);
}

export function deleteCategory(id) {
  return axios.delete(`${apiRoot}/okr/categories/update/${id}/`);
}

export function postScheduleReminder(reminder) {
  return axios.post(`${apiRoot}/okr/scheduler/reminder/create/`, reminder);
}

export function deleteScheduleReminder(id) {
  return axios.delete(`${apiRoot}/okr/scheduler/reminder/${id}/`);
}

export function patchScheduleReminder(reminder) {
  return axios.patch(`${apiRoot}/okr/scheduler/reminder/${reminder.id}/`, reminder);
}

export function patchCompany(id, payload) {
  return axios.patch(`${apiRoot}/okr/company/${id}/`, payload);
}

export function getCompany() {
  return axios.get(`${apiRoot}/okr/company/`);
}

export function getExternalTasks(origin, project) {
  const query = origin && project ? `?${QS({ origin, project })}` : "";
  return axios.get(`${apiRoot}/okr/external-tasks/` + query);
}

export function getExternalTasksMeta() {
  return axios.get(`${apiRoot}/okr/external-tasks/meta/`);
}
