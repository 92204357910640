import { getHoldingList } from "@/services/holdingService.js";
import { stateError, stateInitial, stateLoaded, stateLoading } from "./util";
import store from "@/store";

const initialState = {
  holdings: [],
  holdingsLoadingState: stateInitial
};

const mutations = {
  setHoldings(state, holdings) {
    state.holdings = holdings || [];
  },
  setHoldingsLoadingState(state, loadingState) {
    state.holdingsLoadingState = loadingState;
  }
};

const actions = {
  async loadHoldings(context, options = { reload: false }) {
    if (context.state.holdings && context.state.holdingsLoadingState.loaded && !options.reload) {
      return;
    }

    context.commit("setHoldingsLoadingState", stateLoading);
    try {
      const response = await getHoldingList();
      context.commit("setHoldings", response.data);
    } catch (error) {
      store.commit("setHoldingsLoadingState", {
        ...stateError,
        errorMessage: error
      });
    }

    context.commit("setReportsLoadingState", stateLoaded);
  }
};

const getters = {
  holdings: state => state.holdings,
  authorizedHoldings: state => state.holdings.filter(holding => holding.is_authorized),
  holdingsLoadingState: state => state.holdingsLoadingState
};

export const holdingModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
