const initialState = {
  theme: "dark",
  isAuto: true
};

const mutations = {
  setTheme(state, theme) {
    state.theme = theme;
  },
  setIsAuto(state, isAuto) {
    state.isAuto = isAuto;
  }
};

const actions = {
  toggleTheme(context) {
    const currentTheme = document.documentElement.getAttribute("data-theme");
    let localTheme = null;

    if (localStorage.getItem("theme") === "auto") {
      localTheme = "dark";
      localStorage.setItem("theme", localTheme);
      context.commit("setIsAuto", false);
    } else if (currentTheme === "dark") {
      localTheme = "light";
      localStorage.setItem("theme", localTheme);
      context.commit("setIsAuto", false);
    } else if (currentTheme === "light") {
      localStorage.setItem("theme", "auto");
      localTheme = getThemeFromPreferences();
      context.commit("setIsAuto", true);
    }

    document.documentElement.dataset.theme = localTheme;
    context.commit("setTheme", localTheme);
  },
  initThemeFromPreferences(context) {
    let theme = getThemeFromPreferences();
    const localTheme = localStorage.getItem("theme");

    if (localTheme != null && localTheme !== "auto") {
      context.commit("setIsAuto", false);
    }

    if (localTheme == null) {
      theme = "dark";
      localStorage.setItem("theme", theme);
      context.commit("setIsAuto", false);
    }

    document.documentElement.dataset.theme = theme;
    context.commit("setTheme", theme);
  }
};

const getters = {
  theme: state => state.theme,
  isAuto: state => state.isAuto
};

export const themeModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};

function getThemeFromPreferences() {
  const localTheme = localStorage.getItem("theme");
  const systemThemeLight = window.matchMedia("(prefers-color-scheme: light)");

  if (localTheme != null && localTheme !== "auto") {
    return localTheme;
  } else if (systemThemeLight.matches) {
    return "light";
  } else {
    return "dark";
  }
}
