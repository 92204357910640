/*
This module is responsible for the Multi-Factor Authentication (mfa).
It provides abstractions to activate and deactivate mfa,
get and patch the user phone number,
and get the active mfa methods for the logged in user
*/

import { stateInitial, stateLoading, stateLoaded, stateError } from "./util";

const initialState = {
  user2faConfig: [],
  user2faConfigLoadingState: stateInitial,

  userPhoneNumber: null,

  activateMethod2faLoadingState: stateInitial,

  activateMethod2faConfirmLoadingState: stateInitial,

  deactivateMethod2faLoadingState: stateInitial,

  updateUserPhoneNumberLoadingState: stateInitial
};

const actions = {};

const mutations = {
  user2faConfigLoading(state) {
    state.user2faConfigLoadingState = stateLoading;
  },
  user2faConfigLoaded(state, data) {
    state.user2faConfigLoadingState = stateLoaded;
    state.user2faConfig = data;
  },
  user2faConfigError(state, error) {
    state.user2faConfigLoadingState = stateError;
    state.user2faConfigLoadingState.errorMessage = error;
  },

  userPhoneNumberLoaded(state, data) {
    state.userPhoneNumber = data.phone_number;
  },

  activateMethod2faLoading(state) {
    state.activateMethod2faLoadingState = stateLoading;
  },
  activateMethod2faLoaded(state, message) {
    state.activateMethod2faLoadingState = stateLoaded;
    state.activateMethod2faLoadingState.loadedMessage = message;
  },
  activateMethod2faError(state, error) {
    state.activateMethod2faLoadingState = stateError;
    state.activateMethod2faLoadingState.errorMessage = error;
  },

  activateMethod2faConfirmLoading(state) {
    state.activateMethod2faConfirmLoadingState = stateLoading;
  },
  activateMethod2faConfirmLoaded(state, message) {
    state.activateMethod2faConfirmLoadingState = stateLoaded;
    state.activateMethod2faConfirmLoadingState.loadedMessage = message;
  },
  activateMethod2faConfirmError(state, error) {
    state.activateMethod2faConfirmLoadingState = stateError;
    state.activateMethod2faConfirmLoadingState.errorMessage = error;
  },

  deactivateMethod2faLoading(state) {
    state.deactivateMethod2faLoadingState = stateLoading;
  },
  deactivateMethod2faLoaded(state, message) {
    state.deactivateMethod2faLoadingState = stateLoaded;
    state.deactivateMethod2faLoadingState.loadedMessage = message;
  },
  deactivateMethod2faError(state, error) {
    state.deactivateMethod2faLoadingState = stateError;
    state.deactivateMethod2faLoadingState.errorMessage = error;
  },

  updateUserPhoneNumberLoading(state) {
    state.updateUserPhoneNumberLoadingState = stateLoading;
  },
  updateUserPhoneNumberLoaded(state, message) {
    state.updateUserPhoneNumberLoadingState = stateLoaded;
    state.updateUserPhoneNumberLoadingState.loadedMessage = message;
  },
  updateUserPhoneNumberError(state, error) {
    state.updateUserPhoneNumberLoadingState = stateError;
    state.updateUserPhoneNumberLoadingState.errorMessage = error;
  },

  resetMfaStates(state) {
    state.user2faConfigLoadingState = stateInitial;
    state.activateMethod2faLoadingState = stateInitial;
    state.activateMethod2faConfirmLoadingState = stateInitial;
    state.deactivateMethod2faLoadingState = stateInitial;
    state.updateUserPhoneNumberLoadingState = stateInitial;
  }
};

const getters = {
  user2faConfig: state => state.user2faConfig,
  user2faConfigLoadingState: state => state.user2faConfigLoadingState,
  activateMethod2faLoadingState: state => state.activateMethod2faLoadingState,
  activateMethod2faConfirmLoadingState: state => state.activateMethod2faConfirmLoadingState,
  deactivateMethod2faLoadingState: state => state.deactivateMethod2faLoadingState,
  updateUserPhoneNumberLoadingState: state => state.updateUserPhoneNumberLoadingState,
  userPhoneNumber: state => state.userPhoneNumber
};

export const twoFactorAuthModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
