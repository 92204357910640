import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import i18n from "./translations";
import axios from "axios";
import { AuthInterceptor, AuthErrorInterceptor } from "@/services/interceptors";
import Hotjar from "vue-hotjar";
import ElementUI from "element-ui";
const ElementTiptapPlugin = require("element-tiptap").default;
import "element-ui/lib/theme-chalk/index.css";
import "element-tiptap/lib/index.css";
import ReactWrapper from "@/components/ReactWrapper.vue";

Vue.config.productionTip = false;
const hotjarSiteID = process.env.VUE_APP_HOTJAR_SITE_ID;
if (hotjarSiteID) {
  Vue.use(Hotjar, {
    id: hotjarSiteID,
    isProduction: true
  });
}
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(ElementUI);
Vue.use(ElementTiptapPlugin, {});

// register global components
Vue.component("ReactWrapper", ReactWrapper);

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

axios.interceptors.request.use(AuthInterceptor, () => {});
axios.interceptors.response.use(response => {
  return response;
}, AuthErrorInterceptor);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
