import axios from "axios";
import { apiRoot, warehouseRoot } from "@/preferences";
import { QS } from "@/services/util.js";

export function getUserAppSettings() {
  return axios.get(`${apiRoot}/settings/`);
}

export function updateUserAppSettings(key, payload) {
  return axios.put(`${apiRoot}/settings/${key}/`, payload);
}

export function uploadFile(file, fileName) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("file_name", fileName);
  return axios.post(`${warehouseRoot}/company/file`, formData);
}

export function getFile(fileName) {
  return axios.get(`${warehouseRoot}/company/file?${QS({ file_name: fileName })}`, {
    responseType: "blob"
  });
}

export function deleteFile(fileName) {
  return axios.delete(`${warehouseRoot}/company/file?${QS({ file_name: fileName })}`);
}
