import axios from "axios";
import { QS } from "@/services/util.js";
import { goApiRoot } from "@/preferences";

const proxyUrl = portfolioId => `${goApiRoot}/portfolios/${portfolioId}/proxy/`;
const method = {
  get: "GET",
  post: "POST",
  delete: "DELETE",
  patch: "PATCH"
};
const service = {
  warehouse: "warehouse"
};
const proxy = ({ portfolioId, method, endpoint, service, payload, config }) =>
  axios.post(
    `${proxyUrl(portfolioId)}`,
    {
      method,
      endpoint,
      payload,
      service
    },
    config
  );

export function addPortfolioInstance(payload) {
  return axios.post(`${goApiRoot}/oauth/instance/`, payload);
}

export function getPortfolioList() {
  return axios.get(`${goApiRoot}/portfolios/`);
}

export function getHoldingList() {
  return axios.get(`${goApiRoot}/holdings/`);
}

export function patchHoldingInstance(id, payload) {
  return axios.patch(`${goApiRoot}/oauth/holdings/${id}/`, payload);
}

export function deleteHoldingInstance(id) {
  return axios.delete(`${goApiRoot}/holdings/${id}/`);
}

// proxy endpoints sent to portfolio instances
export function getReportsList(id) {
  return proxy({ portfolioId: id, method: method.get, endpoint: "/api/investor-reporting/list/" });
}

export function getReport(portfolioId, reportId) {
  return proxy({
    portfolioId,
    method: method.get,
    endpoint: `/api/investor-reporting/${reportId}`
  });
}

// get a comment
export function getComment(portfolioId, id, query) {
  return proxy({
    portfolioId,
    method: method.get,
    endpoint: `/api/kpi/comment/get/${id}/?${new URLSearchParams(query)}`
  });
}

export function getComments(portfolioId, query) {
  return proxy({
    portfolioId,
    method: method.get,
    endpoint: `/api/kpi/comment/list/?${new URLSearchParams(query)}`
  });
}

export function getSubComments(portfolioId, query) {
  return proxy({
    portfolioId,
    method: method.get,
    endpoint: `/api/kpi/subcomment/list/?${new URLSearchParams(query)}`
  });
}

// Create a comment
export function addComment(portfolioId, payload) {
  return proxy({ portfolioId, method: method.post, endpoint: `/api/kpi/comment/create/`, payload });
}

// Edit a comment
export function editComment(portfolioId, comment) {
  return proxy({
    portfolioId,
    method: method.patch,
    endpoint: `/api/kpi/comment/edit/${comment.id}/`,
    comment
  });
}

// Delete a comment
export function deleteComment(portfolioId, id) {
  return proxy({ portfolioId, method: method.delete, endpoint: `/api/kpi/comment/edit/${id}/` });
}

export function deletePortfolioInstance(id) {
  return axios.delete(`${goApiRoot}/portfolios/${id}/`);
}

// Proxy export
export function exportExcelFileProxy(portfolioId, reportId) {
  return proxy({
    portfolioId,
    method: method.get,
    endpoint: `/api/investor-reporting/export-excel/${reportId}`,
    config: {
      responseType: "blob"
    }
  });
}

export function exportPdfFileProxy(portfolioId, reportId) {
  return proxy({
    portfolioId,
    method: method.get,
    endpoint: `/api/investor-reporting/export-pdf/${reportId}`,
    config: {
      responseType: "blob"
    }
  });
}

export function listFilesProxy(portfolioId, reportId) {
  return proxy({
    portfolioId,
    method: method.get,
    endpoint: `/warehouse/investor/${reportId}/files`,
    service: service.warehouse
  });
}

export function getFileProxy(portfolioId, reportId, fileName) {
  return proxy({
    portfolioId,
    method: method.get,
    endpoint: `/warehouse/investor/${reportId}/file?${QS({ file_name: fileName })}`,
    service: service.warehouse,
    config: {
      responseType: "blob"
    }
  });
}
