import {
  createUser,
  login,
  loginCode,
  updateUser,
  uploadUsersTemplate,
  updateUserPermissions,
  changePasswordCurrentUser,
  resetPassword,
  resetPasswordConfirm,
  deleteUser,
  getUsers,
  getCurrentUser,
  getPermissionGroups,
  refreshToken,
  getSSOConfig
} from "@/services/authService";
import { getMenu } from "@/services/util.js";
import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
  stateAwaitingUserInput,
  parseJwt
} from "./util";
import router from "@/router";
import i18n from "@/translations";
import { objectToString, capitalizeFirstLetter } from "@/mixins/UtilMixin.js";
import { accessCustomDashboard, accessHoldingReporting } from "@/mixins/PermissionsMixin.js";
import { mapRawMenuItemToLinks, menuItemsToFlatMapWithLinkNames } from "vw/menuUtils";
import { localStorageKeys } from "vw/utils";

const rawUser = localStorage.getItem("user");
const isValidToken = isBrowserStoredTokenValid(rawUser);
const parsedToken = isValidToken ? JSON.parse(rawUser) : undefined;

const initialState = {
  redirect: null,
  loginState: stateInitial,
  userManagementState: stateInitial,
  userEmailState: stateInitial,
  loggedIn: isValidToken,
  token: parsedToken,
  autoLogoutMinutes: undefined,
  autoLogoutTimerId: undefined,
  users: [],
  mentionUsers: [],
  usersEmailMapping: {},
  usersLoadingState: stateInitial,
  userProfile: null,
  userPermissions: [],
  userProfileLoadingState: stateInitial,
  permissionGroups: [],
  usersUploadState: stateInitial,
  updateUserState: stateInitial,
  isAutoLogoutWarningModalVisible: false,
  menu: null,
  ssoConfig: null
};

const actions = {
  login(context, { email, password }) {
    context.commit("removeNotification", "logincredentials");
    context.commit("loginRequest");

    login(email, password).then(
      response => {
        if (response.data.ephemeral_token) {
          context.commit("loginMFARequest");
          localStorage.setItem("ephemeral_token", JSON.stringify(response.data.ephemeral_token));
          router.push({ name: "loginMFA" }).catch(error => {
            console.info(error.message);
          });
        } else {
          context.dispatch("proceedWithLogin", { response });
        }
      },
      error => {
        context.commit("addNotification", {
          id: "logincredentials",
          variant: "danger",
          message: objectToString(error.response.data),
          unique: true
        });
        context.commit("loginError", error);
      }
    );
  },
  loginCode(context, { code }) {
    context.commit("removeNotification", "logincredentials");
    context.commit("loginRequest");

    const ephemeral_token = JSON.parse(localStorage.getItem("ephemeral_token"));
    loginCode(ephemeral_token, code).then(
      response => context.dispatch("proceedWithLogin", { response }),
      error => {
        context.commit("addNotification", {
          id: "logincredentials",
          variant: "danger",
          message: objectToString(error.response.data),
          unique: true
        });
        context.commit("loginError", error);
      }
    );
  },
  proceedWithLogin(context, { response }) {
    context.commit("removeNotification", "logincredentials");
    context.commit("loginSuccess", {
      ...response.data
    });
    localStorage.removeItem("ephemeral_token");
    localStorage.setItem("user", JSON.stringify(response.data));
    context.dispatch("setAutoLogoutMinutes");
    router.push(context.state.redirect || "/").catch(error => {
      console.info(error.message);
    });
    context.commit("resetLoadingRedirect");
  },
  setAutoLogoutMinutes(context) {
    // Get the remaining time of the refresh token
    // The token can be undefined! Check it with optional chaining
    const tokenExpire = getExpFromTokenOrZero(context.state.token?.refresh);
    const tokenRemainingSeconds = secondsUntilNow(tokenExpire);

    // If no more time left, logout immediately
    if (tokenRemainingSeconds <= 0) {
      context.dispatch("logout");
    }

    // Add an epsilon of almost 1 min to trigger the `AutoLogoutWarningModal` sooner
    // this epsilon should remain under 1 min to keep the displayed number of remaining minutes accurate
    const epsilonSeconds = 59;
    const remainingSeconds = tokenRemainingSeconds + epsilonSeconds;
    const autoLogoutMinutes = remainingSeconds >= 0 ? parseInt(remainingSeconds / 60) : 0;
    context.commit("autoLogoutMinutesUpdate", autoLogoutMinutes);
  },
  logout(context) {
    // remove any other unwanted cached data in the browser
    localStorage.removeItem(localStorageKeys.financialDashboard);

    localStorage.removeItem("user");
    context.commit("logout");
    context.commit("autoLogoutTimerClear");
    router.push("/login");
  },
  startAutoLogoutTimer(context) {
    context.dispatch("setAutoLogoutMinutes");

    var timerId = setInterval(function () {
      context.dispatch("setAutoLogoutMinutes");
      if (context.state.autoLogoutMinutes == 0) {
        context.dispatch("logout");
      }
    }, 10000);
    context.commit("autoLogoutTimerSet", timerId);
  },
  setLoadingRedirect(context, to) {
    context.commit("loadingRedirect", to);
  },
  tokenRefresh(context, token) {
    context.commit("refreshToken", token);
    localStorage.setItem("user", JSON.stringify(token));
    context.dispatch("setAutoLogoutMinutes");
  },
  refreshToken(context) {
    if (context.getters.token?.refresh) {
      refreshToken(context.getters.token.refresh).then(res => {
        context.dispatch("tokenRefresh", res.data);
      });
    } else {
      context.dispatch("logout");
    }
  },
  createUser(context, { data, permissionsData }) {
    context.commit("usersLoading");
    return new Promise((resolve, reject) => {
      const createData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: data.password != null ? data.password : generatePassword()
      };
      createUser(createData).then(
        createdResponse => {
          // Send password reset email
          if (data.password == null) {
            const resetData = {
              email: data.email
            };
            context.dispatch("resetPasswordForEmail", resetData);
          }

          // Set permissions for created user id
          if (permissionsData) {
            updateUserPermissions(createdResponse.data.id, permissionsData).then(
              () => {
                context.dispatch("loadUsers", true);
                resolve();
              },
              error => {
                context.dispatch("loadUsers", true);
                reject(error);
              }
            );
          } else {
            context.dispatch("loadUsers", true);
            resolve();
          }
        },
        error => {
          context.dispatch("loadUsers", true);
          reject(error);
        }
      );
    });
  },
  changePasswordCurrentUser(context, data) {
    context.commit("userManagementRequest");
    return new Promise((resolve, reject) => {
      changePasswordCurrentUser(data).then(
        () => {
          context.commit("userManagementSuccess", i18n.t("success_change_password"));
          resolve();
        },
        error => {
          context.commit("userManagementError");
          reject(error);
        }
      );
    });
  },
  deleteUser(context, id) {
    context.commit("userManagementRequest");
    deleteUser(id).then(
      () => {
        context.commit("userManagementSuccess");
        context.dispatch("loadUsers", true);
      },
      error => {
        context.commit("userManagementError", error.response.data);
      }
    );
  },
  resetPasswordForEmail(context, data) {
    context.commit("userEmailRequest");
    resetPassword(data).then(
      () => {
        context.commit("userEmailSuccess", i18n.t("password_send_successfully"));
      },
      error => {
        context.commit("userEmailError", (error.response.data = i18n.t("password_send_error")));
      }
    );
  },
  resetPasswordUserManagement(context, data) {
    context.commit("userManagementRequest");
    resetPassword(data).then(
      () => {
        context.commit("userManagementSuccess", i18n.t("success_create_user_send_email"));
      },
      error => {
        context.commit("userManagementError", error.response.data);
      }
    );
  },
  resetPasswordConfirm(context, data) {
    context.commit("userManagementRequest");
    resetPasswordConfirm(data).then(
      () => {
        context.commit("removeNotification", "resetpasswordconfirm");
        context.commit("userManagementSuccess");
        router.push("/login");
      },
      error => {
        context.commit("removeNotification", "resetpasswordconfirm");
        let message = "";
        for (let i in error.response.data) {
          for (let j in error.response.data[i]) {
            message = message + error.response.data[i][j];
            message = message + "\n";
          }
        }
        context.commit("addNotification", {
          id: "resetpasswordconfirm",
          variant: "danger",
          message: message,
          unique: true
        });
        context.commit("userManagementError", error.response.data);
      }
    );
  },
  updateUser(context, { id, data, permissionsData }) {
    context.commit("usersLoading");
    return new Promise((resolve, reject) => {
      updateUser(id, data).then(
        () => {
          if (permissionsData) {
            updateUserPermissions(id, permissionsData).then(
              () => {
                context.dispatch("loadUsers", true);
                resolve();
              },
              error => {
                context.dispatch("loadUsers", true);
                reject(error);
              }
            );
          } else {
            context.dispatch("loadUsers", true);
            resolve();
          }
        },
        error => {
          context.commit("updateUserStateError", objectToString(error.response.data));
          context.dispatch("loadUsers", true);
          reject(error);
        }
      );
    });
  },
  uploadUsersTemplate(context, options) {
    context.commit("usersUploadStateLoading");
    uploadUsersTemplate(options.file).then(
      response => {
        var message = i18n.t("success_message_users_upload");
        var containsErrors = false;
        if (response.data.errors.length > 0) {
          message = i18n.t("erroneous_message_users_upload");
          containsErrors = true;
        }
        context.commit("usersUploadStateLoaded", {
          message: message,
          containsErrors: containsErrors
        });
        context.dispatch("loadUsers", true);
      },
      error => {
        context.commit("usersUploadStateError", error);
      }
    );
  },
  loadUsers(context, reload) {
    if (
      !reload &&
      (context.state.usersLoadingState.loaded || context.state.usersLoadingState.loading)
    ) {
      return;
    }
    context.commit("usersLoading");
    getUsers().then(
      response => {
        const filteredUsers = response.data
          .filter(user => user.is_superuser === false)
          .sort((a, b) => a.id < b.id);
        context.commit("usersLoaded", filteredUsers);
      },
      error => {
        context.commit("usersError", error);
      }
    );
  },
  async loadMenu(context, opts = { reload: false }) {
    if (context.state.menu == null || opts.reload) {
      const response = await getMenu();
      const items = response.data.map(el => mapRawMenuItemToLinks(el, { i18n }));
      const flatMap = menuItemsToFlatMapWithLinkNames(items);
      context.commit("menuLoaded", { items, flatMap });
    }
    return context.state.menu;
  },
  loadUserProfile(context, opts = { reload: false }) {
    const { reload } = opts;
    return new Promise((resolve, reject) => {
      if (context.state.userProfileLoadingState.loaded && !reload) {
        // NOTE: this data should not change and thus a reload is not neccessary
        resolve(context.state.userProfile);
        return;
      }
      context.commit("userProfileLoading");
      getCurrentUser().then(
        response => {
          context.commit("userProfileLoaded", response.data);
          resolve(response.data);
        },
        error => {
          context.commit("userProfileError", error);
          reject();
        }
      );
    });
  },
  loadPermissionGroups(context) {
    getPermissionGroups().then(
      response => {
        context.commit("permissionGroupsLoaded", response.data);
      },
      () => {
        context.commit("permissionGroupsLoaded", []);
      }
    );
  },
  async loadSSOConfig(context) {
    if (context.state.ssoConfig != null) return;

    try {
      const response = await getSSOConfig();
      context.commit("updateSSOConfig", response.data);
    } catch (e) {
      console.error(e);
    }
  }
};

const mutations = {
  loadingRedirect(state, to) {
    state.redirect = to;
  },
  resetLoadingRedirect(state) {
    state.redirect = null;
  },
  usersLoading(state) {
    state.usersLoadingState = stateLoading;
  },
  updateUserProfileField(state, { field, fieldValue }) {
    state.userProfile[field] = fieldValue;
  },
  usersLoaded(state, users) {
    state.users = users;
    state.mentionUsers = [];
    state.usersEmailMapping = {};
    let firstName;
    let lastName;
    let userId;
    // Users data structure for nagivating the users for mentioning
    for (let i = 0; i < users.length; i++) {
      firstName = capitalizeFirstLetter(users[i].first_name);
      lastName = capitalizeFirstLetter(users[i].last_name);
      userId = users[i].id;
      state.usersEmailMapping[users[i].email] = {
        fullName: firstName + " " + lastName,
        id: userId
      };
      let userPremissions = users[i].user_permissions;
      // Add groups permissions
      for (let j = 0; j < users[i].groups.length; j++) {
        userPremissions = userPremissions.concat(users[i].groups[j].permissions);
      }
      const mentionUsersObject = {
        first_name: firstName,
        last_name: lastName,
        value: firstName + " " + lastName,
        email: users[i].email,
        id: userId,
        is_staff: users[i].is_staff,
        permissions: userPremissions
      };
      state.mentionUsers.push(mentionUsersObject);
    }
    state.usersLoadingState = stateLoaded;
  },
  usersError(state, error) {
    state.usersLoadingState = stateError;
    state.usersLoadingState.errorMessage = error;
  },
  permissionGroupsLoaded(state, groups) {
    state.permissionGroups = groups;
  },
  loginRequest(state) {
    state.loginState = stateLoading;
  },
  loginMFARequest(state) {
    state.loginState = stateAwaitingUserInput;
  },
  loginSuccess(state, token) {
    state.token = token;
    state.loggedIn = true;
    state.loginState = stateLoaded;
  },
  loginError(state, error) {
    state.loginState = stateError;
    state.loginState.errorMessage = error.message;
  },
  logout(state) {
    state.loginState = stateInitial;
    state.loggedIn = false;
    state.token = undefined;
    state.users = [];
    state.usersEmailMapping = {};
    state.mentionUsers = [];
    state.usersLoadingState = stateInitial;
    state.userProfileLoadingState = stateInitial;
    state.userProfile = null;
    state.userPermissions = [];
    state.permissionGroups = [];
    state.menu = null;

    clearInterval(state.timer);
  },
  refreshToken(state, token) {
    state.token = token;
  },
  userManagementRequest(state) {
    state.userManagementState = stateLoading;
  },
  userManagementSuccess(state, message) {
    state.userManagementState = stateLoaded;
    state.userManagementState.loadedMessage = message;
  },
  userEmailRequest(state) {
    state.userEmailState = stateLoading;
  },
  userEmailSuccess(state, message) {
    state.userEmailState = stateLoaded;
    state.userEmailState.loadedMessage = message;
  },
  userManagementError(state, message) {
    state.userManagementState = stateError;
    state.userManagementState.errorMessage = message;
  },
  userEmailError(state, message) {
    state.userEmailState = stateError;
    state.userEmailState.errorMessage = message;
  },
  autoLogoutMinutesUpdate(state, autoLogoutMinutes) {
    state.autoLogoutMinutes = autoLogoutMinutes;
  },
  autoLogoutTimerSet(state, timerId) {
    state.autoLogoutTimerId = timerId;
  },
  autoLogoutTimerClear(state) {
    clearInterval(state.autoLogoutTimerId);
  },
  userProfileLoading(state) {
    state.userProfileLoadingState = stateLoading;
  },
  userProfileLoaded(state, user) {
    // Combine group and user permissions
    state.userPermissions = user.user_permissions;
    for (let group of user.groups) {
      state.userPermissions = state.userPermissions.concat(group.permissions);
    }
    // If the user has access to at least one of the custom dashboards, then we add the accessCustomDashboard to the user permissions
    if (user.access_custom_dashboard) {
      state.userPermissions = state.userPermissions.concat(accessCustomDashboard());
    }
    // If the instance is a holding, then we add the accessHoldingReporting to the user permissions
    if (user.is_holding_instance) {
      state.userPermissions = state.userPermissions.concat(accessHoldingReporting());
    }

    state.userProfile = user;
    state.userProfileLoadingState = stateLoaded;
  },
  userProfileError(state, error) {
    state.userProfileLoadingState = stateError;
    state.userProfileLoadingState.error = error;
  },
  usersUploadStateLoading(state) {
    state.usersUploadState = stateLoading;
  },
  usersUploadStateLoaded(state, payload) {
    state.usersUploadState = stateLoaded;
    state.usersUploadState.loadedUploadMessage = payload.message;
    state.usersUploadState.containsErrors = payload.containsErrors;
  },
  usersUploadStateError(state, error) {
    state.usersUploadState = stateError;
    state.usersUploadState.errorMessage = error;
  },
  updateUserStateError(state, error) {
    state.updateUserState = stateError;
    state.updateUserState.errorMessage = error;
  },
  isAutoLogoutWarningModalVisibleUpdate(state, isVisible) {
    state.isAutoLogoutWarningModalVisible = isVisible;
  },
  menuLoaded(state, menu) {
    state.menu = menu;
  },
  updateSSOConfig(state, config) {
    state.ssoConfig = config;
  }
};

const getters = {
  isLoggedIn: state => state.loggedIn,
  loginState: state => state.loginState,
  userManagementState: state => state.userManagementState,
  userEmailState: state => state.userEmailState,
  token: state => state.token,
  users: state => state.users,
  mentionUsers: state => state.mentionUsers,
  usersEmailMapping: state => state.usersEmailMapping,
  usersLoadingState: state => state.usersLoadingState,
  autoLogoutMinutes: state => state.autoLogoutMinutes,
  userProfile: state => state.userProfile,
  userPermissions: state => state.userPermissions,
  userProfileLoadingState: state => state.userProfileLoadingState,
  permissionGroups: state => state.permissionGroups,
  usersUploadState: state => state.usersUploadState,
  updateUserState: state => state.updateUserState,
  isAutoLogoutWarningModalVisible: state => state.isAutoLogoutWarningModalVisible,
  menu: state => state.menu,
  ssoConfig: state => state.ssoConfig
};

export const authModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};

/**
 * Extracts the expiration date (seconds since Epoch) from the passed token.
 * Returns 0 if token invalid
 * @param {Object} token JWT Token as JS Object
 * @param {str} tokenType Either 'access' or 'refresh'
 * @returns token expiration date (seconds since Epoch) or 0
 */
export function getExpFromTokenOrZero(token) {
  try {
    return token ? parseJwt(token).exp : 0;
  } catch {
    return 0;
  }
}

export function isBrowserStoredTokenValid(tokenString) {
  const tokenExpire = getExpFromTokenOrZero(JSON.parse(tokenString)?.refresh);
  const tokenRemainingSeconds = secondsUntilNow(tokenExpire);

  const isValid = tokenRemainingSeconds > 0;
  if (!isValid) {
    localStorage.removeItem("user");
  }

  return isValid;
}

/**
 * Get the distance in seconds between the passed timestamp and the current time
 * @param {Number} timestamp seconds since Epoch
 * @returns seconds until now (can be negative)
 */
export function secondsUntilNow(timestamp) {
  const secondsNow = Date.now() / 1000;
  return timestamp - secondsNow;
}

function generatePassword() {
  return Array(30)
    .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$")
    .map(
      x =>
        x[Math.floor((crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) * x.length)]
    )
    .join("");
}
