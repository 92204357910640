import { stateInitial } from "./util";
import { stateLoading, stateLoaded, stateError } from "./util";
import {
  getComparativeDealSize,
  getSalesPipelineDevelopment,
  getSalesPipeline,
  getListView,
  getStagesPercentages,
  getListViewFinancial,
  getDevelopmentShare,
  getDatevMarimekko,
  getListViewRecurringRevenue,
  getListViewProductManagement,
  getListViewProjectProfitability,
  getSalesTarget,
  getListViewMqlSql,
  getListViewNumberEmployee
} from "@/services/deepDrillsService";
import { getDeepDrills } from "@/services/kpiService";
import { tryExecCtx } from "vw/asyncUtils";

const initialState = {
  dealSizeLoadingState: stateInitial,
  dealSize: [],

  financialMarimekkoLoadingState: stateInitial,
  financialMarimekko: [],

  salesPipelineLoadingState: stateInitial,
  salesPipeline: [],

  stagesPercentagesLoadingState: stateInitial,
  stagesPercentages: [],

  salesPipelineDevLoadingState: stateInitial,
  salesPipelineDev: [],

  developmentShareLoadingState: stateInitial,
  developmentShare: [],

  listViewLoadingState: stateInitial,
  listView: [],

  listViewFinancialLoadingState: stateInitial,
  listViewFinancial: [],

  listViewRecurringRevenueLoadingState: stateInitial,
  listViewRecurringRevenue: [],

  listViewProductManagementLoadingState: stateInitial,
  listViewProductManagement: [],

  listViewProjectProfitabilityLoadingState: stateInitial,
  listViewProjectProfitability: [],

  listViewMqlSqlLoadingState: stateInitial,
  listViewMqlSql: [],

  listViewNumberEmployeeLoadingState: stateInitial,
  listViewNumberEmployee: [],

  salesTargetLoadingState: stateInitial,
  salesTarget: [],

  activeDeepDrillsIds: []
};

const mutations = {
  dealSizeLoading(state) {
    state.dealSizeLoadingState = stateLoading;
  },

  salesTargetLoading(state) {
    state.dealSizeLoadingState = stateLoading;
  },

  financialMarimekkoLoading(state) {
    state.financialMarimekkoLoadingState = stateLoading;
  },

  stagesPercentagesLoading(state) {
    state.stagesPercentagesLoadingState = stateLoading;
  },

  salesPipelineLoading(state) {
    state.salesPipelineLoadingState = stateLoading;
  },

  salesPipelineDevLoading(state) {
    state.salesPipelineDevLoadingState = stateLoading;
  },

  developmentShareLoading(state) {
    state.developmentShareLoadingState = stateLoading;
  },

  listViewLoading(state) {
    state.listViewLoadingState = stateLoading;
  },

  listViewFinancialLoading(state) {
    state.listViewFinancialLoadingState = stateLoading;
  },

  listViewRecurringRevenueLoading(state) {
    state.listViewRecurringRevenueLoadingState = stateLoading;
  },

  listViewProductManagementLoading(state) {
    state.listViewProductManagementLoadingState = stateLoading;
  },

  listViewProjectProfitabilityLoading(state) {
    state.listViewProjectProfitabilityLoadingState = stateLoading;
  },
  listViewMqlSqlLoading(state) {
    state.listViewMqlSqlLoadingState = stateLoading;
  },

  listViewNumberEmployeeLoading(state) {
    state.listViewNumberEmployeeLoadingState = stateLoading;
  },

  dealSizeLoaded(state, dealSizeData) {
    state.dealSize = dealSizeData;
    state.dealSizeLoadingState = stateLoaded;
  },

  salesTargetLoaded(state, salesTargetData) {
    state.salesTarget = salesTargetData;
    state.salesTargetLoadingState = stateLoaded;
  },

  financialMarimekkoLoaded(state, financialMarimekkoData) {
    state.financialMarimekko = financialMarimekkoData;
    state.financialMarimekkoLoadingState = stateLoaded;
  },

  salesPipelineLoaded(state, salesPipelineData) {
    state.salesPipeline = salesPipelineData;
    state.salesPipelineLoadingState = stateLoaded;
  },

  developmentShareLoaded(state, developmentShareData) {
    state.developmentShare = developmentShareData;
    state.developmentShareLoadingState = stateLoaded;
  },

  stagesPercentagesLoaded(state, stagesPercentagesData) {
    state.stagesPercentages = stagesPercentagesData;
    state.stagesPercentagesLoadingState = stateLoaded;
  },

  salesPipelineDevLoaded(state, salesPipelineDevData) {
    let salesPipelineDevDataUpdate = salesPipelineDevData.map(function (pipeLineData) {
      let pipeLineDataUpdate = {};
      pipeLineData.Date = pipeLineData.Date.replaceAll("-", ".");
      for (var key in pipeLineData) {
        pipeLineDataUpdate[key] = pipeLineData[key] + "";
      }
      return pipeLineDataUpdate;
    });
    state.salesPipelineDev = salesPipelineDevDataUpdate;
    state.salesPipelineDevLoadingState = stateLoaded;
  },

  listViewLoaded(state, listViewData) {
    state.listView = listViewData;
    state.listViewLoadingState = stateLoaded;
  },

  listViewFinancialLoaded(state, listViewFinancialData) {
    state.listViewFinancial = Object.keys(listViewFinancialData).length
      ? listViewFinancialData
      : [];
    state.listViewFinancialLoadingState = stateLoaded;
  },

  listViewRecurringRevenueLoaded(state, listViewRecurringRevenueData) {
    state.listViewRecurringRevenue = Object.keys(listViewRecurringRevenueData).length
      ? listViewRecurringRevenueData
      : [];
    state.listViewRecurringRevenueLoadingState = stateLoaded;
  },

  listViewProductManagementLoaded(state, listViewProductManagementData) {
    state.listViewProductManagement = Object.keys(listViewProductManagementData).length
      ? listViewProductManagementData
      : [];
    state.listViewProductManagementLoadingState = stateLoaded;
  },

  listViewProjectProfitabilityLoaded(state, listViewProjectProfitabilityData) {
    state.listViewProjectProfitability = Object.keys(listViewProjectProfitabilityData).length
      ? listViewProjectProfitabilityData
      : [];
    state.listViewProjectProfitabilityLoadingState = stateLoaded;
  },

  listViewMqlSqlLoaded(state, listViewMqlSqlData) {
    state.listViewMqlSql = Object.keys(listViewMqlSqlData).length ? listViewMqlSqlData : [];
    state.listViewMqlSqlLoadingState = stateLoaded;
  },

  listViewNumberEmployeeLoaded(state, listViewNumberEmployeeData) {
    state.listViewNumberEmployee = Object.keys(listViewNumberEmployeeData).length
      ? listViewNumberEmployeeData
      : [];
    state.listViewNumberEmployeeLoadingState = stateLoaded;
  },

  dealSizeError(state, error) {
    state.dealSizeLoadingState = stateError;
    state.dealSizeLoadingState.errorMessage = error;
  },

  salesTargetError(state, error) {
    state.salesTargetLoadingState = stateError;
    state.salesTargetLoadingState.errorMessage = error;
  },

  financialMarimekkoError(state, error) {
    state.financialMarimekkoLoadingState = stateError;
    state.financialMarimekkoLoadingState.errorMessage = error;
  },

  stagesPercentagesError(state, error) {
    state.stagesPercentagesLoadingState = stateError;
    state.stagesPercentagesLoadingState.errorMessage = error;
  },

  salesPipelineError(state, error) {
    state.salesPipelineLoadingState = stateError;
    state.salesPipelineLoadingState.errorMessage = error;
  },

  salesPipelineDevError(state, error) {
    state.salesPipelineDevLoadingState = stateError;
    state.salesPipelineDevLoadingState.errorMessage = error;
  },

  developmentShareError(state, error) {
    state.developmentShareLoadingState.errorMessage = error;
    state.developmentShareLoadingState = stateError;
  },

  listViewError(state, error) {
    state.listViewLoadingState = stateError;
    state.listViewLoadingState.errorMessage = error;
  },

  listViewFinancialError(state, error) {
    state.listViewFinancialLoadingState = stateError;
    state.listViewFinancialLoadingState.errorMessage = error;
  },

  listViewRecurringRevenueError(state, error) {
    state.listViewRecurringRevenueLoadingState = stateError;
    state.listViewRecurringRevenueLoadingState.errorMessage = error;
  },

  listViewProductManagementError(state, error) {
    state.listViewProductManagementLoadingState = stateError;
    state.listViewProductManagementLoadingState.errorMessage = error;
  },

  listViewProjectProfitabilityError(state, error) {
    state.listViewProjectProfitabilityLoadingState = stateError;
    state.listViewProjectProfitabilityLoadingState.errorMessage = error;
  },

  listViewMqlSqlError(state, error) {
    state.listViewMqlSqlLoadingState = stateError;
    state.listViewMqlSqlLoadingState.errorMessage = error;
  },

  listViewNumberEmployeeError(state, error) {
    state.listViewNumberEmployeeLoadingState = stateError;
    state.listViewNumberEmployeeLoadingState.errorMessage = error;
  },

  setActiveDeepDrillsIds(state, ids) {
    state.activeDeepDrillsIds = ids;
  }
};

const actions = {
  async loadActiveDeepDrills(context) {
    await tryExecCtx({}, async () => {
      const response = await getDeepDrills();
      const activeIds = response.data.map(dd => dd.id);
      context.commit("setActiveDeepDrillsIds", activeIds);
    });
  },
  loadDealSizeData(context) {
    context.commit("dealSizeLoading");
    getComparativeDealSize().then(
      response => {
        context.commit("dealSizeLoaded", response.data);
      },
      error => {
        context.commit("dealSizeError", error);
      }
    );
  },
  loadSalesTargetData(context, query) {
    context.commit("salesTargetLoading");
    getSalesTarget(query).then(
      response => {
        context.commit("salesTargetLoaded", response.data);
      },
      error => {
        context.commit("salesTargetError", error);
      }
    );
  },
  loadFinancialMarimekkoData(context, query) {
    context.commit("financialMarimekkoLoading");
    getDatevMarimekko(query).then(
      response => {
        context.commit("financialMarimekkoLoaded", response.data);
      },
      error => {
        context.commit("financialMarimekkoError", error);
      }
    );
  },
  loadDevelopmentShare(context) {
    context.commit("developmentShareLoading");
    getDevelopmentShare().then(
      response => {
        context.commit("developmentShareLoaded", response.data);
      },
      error => {
        context.commit("developmentShareError", error);
      }
    );
  },
  loadStagesPercentagesData(context) {
    context.commit("stagesPercentagesLoading");
    getStagesPercentages().then(
      response => {
        context.commit("stagesPercentagesLoaded", response.data);
      },
      error => {
        context.commit("stagesPercentagesError", error);
      }
    );
  },
  loadSalesPipelineData(context) {
    context.commit("salesPipelineLoading");
    getSalesPipeline().then(
      response => {
        context.commit("salesPipelineLoaded", response.data);
      },
      error => {
        context.commit("salesPipelineError", error);
      }
    );
  },
  loadSalesPipelineDevData(context) {
    context.commit("salesPipelineDevLoading");
    getSalesPipelineDevelopment().then(
      response => {
        context.commit("salesPipelineDevLoaded", response.data);
      },
      error => {
        context.commit("salesPipelineDevError", error);
      }
    );
  },
  loadListViewData(context) {
    context.commit("listViewLoading");
    getListView().then(
      response => {
        context.commit("listViewLoaded", response.data);
      },
      error => {
        context.commit("listViewError", error);
      }
    );
  },
  loadListViewFinancialData(context, query) {
    context.commit("listViewFinancialLoading");
    getListViewFinancial(query).then(
      response => {
        context.commit("listViewFinancialLoaded", response.data);
      },
      error => {
        context.commit("listViewFinancialError", error);
      }
    );
  },
  loadListViewRecurringRevenueData(context, query) {
    context.commit("listViewRecurringRevenueLoading");
    getListViewRecurringRevenue(query).then(
      response => {
        context.commit("listViewRecurringRevenueLoaded", response.data);
      },
      error => {
        context.commit("listViewRecurringRevenueError", error);
      }
    );
  },
  loadListProductManagementData(context, query) {
    context.commit("listViewProductManagementLoading");
    getListViewProductManagement(query).then(
      response => {
        context.commit("listViewProductManagementLoaded", response.data);
      },
      error => {
        context.commit("listViewProductManagementError", error);
      }
    );
  },
  loadListProjectProfitabilityData(context, query) {
    context.commit("listViewProjectProfitabilityLoading");
    getListViewProjectProfitability(query).then(
      response => {
        context.commit("listViewProjectProfitabilityLoaded", response.data);
      },
      error => {
        context.commit("listViewProjectProfitabilityError", error);
      }
    );
  },
  loadListViewMqlSqlData(context, query) {
    context.commit("listViewMqlSqlLoading");
    getListViewMqlSql(query).then(
      response => {
        context.commit("listViewMqlSqlLoaded", response.data);
      },
      error => {
        context.commit("listViewMqlSqlError", error);
      }
    );
  },
  loadListViewNumberEmployeeData(context, query) {
    context.commit("listViewNumberEmployeeLoading");
    getListViewNumberEmployee(query).then(
      response => {
        context.commit("listViewNumberEmployeeLoaded", response.data);
      },
      error => {
        context.commit("listViewNumberEmployeeError", error);
      }
    );
  }
};

const getters = {
  dealSizeLoadingState: state => state.dealSizeLoadingState,
  dealSize: state => state.dealSize,

  salesTargetLoadingState: state => state.salesTargetLoadingState,
  salesTarget: state => state.salesTarget,

  financialMarimekkoLoadingState: state => state.financialMarimekkoLoadingState,
  financialMarimekko: state => state.financialMarimekko,

  stagesPercentagesLoadingState: state => state.stagesPercentagesLoadingState,
  stagesPercentages: state => state.stagesPercentages,

  salesPipelineLoadingState: state => state.salesPipelineLoadingState,
  salesPipeline: state => state.salesPipeline,

  salesPipelineDevLoadingState: state => state.salesPipelineDevLoadingState,
  salesPipelineDev: state => state.salesPipelineDev,

  developmentShareLoadingState: state => state.developmentShareLoadingState,
  developmentShare: state => state.developmentShare,

  listViewLoadingState: state => state.listViewLoadingState,
  listView: state => state.listView,

  listViewFinancialLoadingState: state => state.listViewFinancialLoadingState,
  listViewFinancial: state => state.listViewFinancial,

  listViewRecurringRevenueLoadingState: state => state.listViewRecurringRevenueLoadingState,
  listViewRecurringRevenue: state => state.listViewRecurringRevenue,

  listViewProductManagementLoadingState: state => state.listViewProductManagementLoadingState,
  listViewProductManagement: state => state.listViewProductManagement,

  listViewProjectProfitabilityLoadingState: state => state.listViewProjectProfitabilityLoadingState,
  listViewProjectProfitability: state => state.listViewProjectProfitability,

  listViewMqlSqlLoadingState: state => state.listViewMqlSqlLoadingState,
  listViewMqlSql: state => state.listViewMqlSql,

  listViewNumberEmployee: state => state.listViewNumberEmployee,
  listViewNumberEmployeeLoadingState: state => state.listViewNumberEmployeeLoadingState,

  activeDeepDrillsIds: state => state.activeDeepDrillsIds
};

export const deepDrillsModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
