import axios from "axios";
import { warehouseRoot, apiRoot } from "@/preferences";
import { QS } from "@/services/util.js";
import { createAbortController, keyMapping } from "vw/signals.js";

export function getComparativeDealSize() {
  return axios.get(`${warehouseRoot}/dealsize`);
}

export function getStagesPercentages() {
  return axios.get(`${warehouseRoot}/stages`);
}

export function getSalesPipelineDevelopment() {
  return axios.get(`${warehouseRoot}/salespipelinedev`);
}

export function getRecurringRevenue(query) {
  return axios.get(`${warehouseRoot}/recurringrevenue?${QS(query)}`, {
    signal: createAbortController(keyMapping.recurringRevenue)
  });
}

export function getSalesPipeline() {
  return axios.get(`${warehouseRoot}/salespipeline`);
}

export function getListViewFinancial(query) {
  return axios.get(
    `${warehouseRoot}/listview/datev?${query.idList}&startDate=${query.startDate}&endDate=${query.endDate}`
  );
}

export function getListViewRecurringRevenue(query) {
  return axios.get(
    `${warehouseRoot}/listview/recurringrevenue?startDate=${query.startDate}&endDate=${query.endDate}`
  );
}

export function getListViewProductManagement(query) {
  return axios.get(
    `${warehouseRoot}/listview/productmgmt?startDate=${query.startDate}&endDate=${query.endDate}`
  );
}

export function getListViewProjectProfitability(query) {
  return axios.get(
    `${warehouseRoot}/listview/projectprofitability?startDate=${query.startDate}&endDate=${query.endDate}`
  );
}

export function getListViewNumberEmployee(query) {
  return axios.get(
    `${warehouseRoot}/listview/employees?startDate=${query.startDate}&endDate=${query.endDate}`
  );
}

export function getSalesTarget(query) {
  return axios.get(`${warehouseRoot}/salestarget?quarter=${query.quarter}&year=${query.year}`);
}

export function getDatevMarimekko(query) {
  return axios.get(
    `${warehouseRoot}/datevmarimekko?${query.idList}&kpiId=${query.kpiId}&startDate=${query.startDate}&endDate=${query.endDate}`
  );
}

export function getDevelopmentShare() {
  return axios.get(`${warehouseRoot}/developmentshare`);
}

export function getListViewMqlSql(query) {
  return axios.get(`
    ${warehouseRoot}/listview/mqlsql?${QS(query)}`);
}

export function getListView(query) {
  return axios.get(`${warehouseRoot}/listview?${QS(query)}`);
}

export function getCalculatedKpiListView(query, kpi_formula) {
  return axios.post(`${warehouseRoot}/listview/calculated?${QS(query)}`, { kpi_formula });
}

export function getCohortAnalysis(query) {
  return axios.get(`${warehouseRoot}/cohortanalysis?${QS(query)}`);
}

export function getCrmLeadSource(query) {
  return axios.get(`
    ${warehouseRoot}/crmleadsource?${QS(query)}`);
}

export function getSalesForecastByProbability(query) {
  return axios.get(`${warehouseRoot}/salesforecastbyprobability?${QS(query)}`, {
    signal: createAbortController(keyMapping.salesForecastByProbability)
  });
}

export function getCrmLeadIndustry(query) {
  return axios.get(`
    ${warehouseRoot}/crmleadindustry?${QS(query)}`);
}

export function getCrmLeadSourceOwner(query) {
  return axios.get(`
    ${warehouseRoot}/crmleadsource/owner?${QS(query)}`);
}

export function getCrmLeadSourceIndustry(query) {
  return axios.get(`
    ${warehouseRoot}/crmleadsource/industry?${QS(query)}`);
}

export function downloadListViewTemplate(query) {
  return axios({
    url: `${warehouseRoot}/listview/template?${QS(query)}`,
    method: "GET",
    responseType: "blob"
  });
}

export function downloadListViewTemplateCalculatedKpi(query, payload) {
  return axios({
    url: `${warehouseRoot}/listview/template?${QS(query)}`,
    method: "POST",
    responseType: "blob",
    data: {
      kpi_formula: payload.kpi_formula
    }
  });
}

export function getSalesPipelinePredictions() {
  return axios.get(`
    ${warehouseRoot}/salespipelineprediction`);
}

// eslint-disable-next-line no-unused-vars
export function getKpiDrillDown(query) {
  return axios.get(`${warehouseRoot}/timeseries/kpi/hourly/${query.kpi_id}?${QS(query)}`);
}

export function okrKeyResultsRecommendations(payload) {
  return axios.post(`${warehouseRoot}/recommendation/okr`, payload);
}

export function getConversionMatrix(query) {
  return axios.get(`${warehouseRoot}/opportunityconversion?${QS(query)}`);
}

export function getGenericDeepdrillsIds() {
  return axios.get(`${apiRoot}/kpi/deepdrills/generic/list/`);
}

export function getGenericDeepdrill(query) {
  return axios.get(`${apiRoot}/kpi/deepdrills/generic/?${QS(query)}`, {
    signal: createAbortController(keyMapping.genericDeepdrill)
  });
}

export function getGenericLineDeepdrill(query) {
  return axios.get(`${warehouseRoot}/deepdrill/generic/line?${QS(query)}`);
}
