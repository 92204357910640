import axios from "axios";
import { QS } from "@/services/util.js";
import { apiRoot, goApiRoot, warehouseRoot } from "@/preferences";

export function getInvestorReportingSnapshots() {
  return axios.get(`${apiRoot}/investor-reporting/list/`);
}

// NOTE: this endpoint is called by the "print" page
// The default authorization workflow is not implemented here,
// and the access token should be passed manually
export function getInvestorReportingSnapshot(id, token = false) {
  const headers = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    : {};
  return axios.get(`${apiRoot}/investor-reporting/${id}/`, headers);
}

export function createInvestorReportingSnapshot(payload) {
  return axios.post(`${apiRoot}/investor-reporting/create/`, payload);
}

export function updateInvestorReportingSnapshot(id, payload) {
  return axios.patch(`${apiRoot}/investor-reporting/update/${id}/`, payload);
}

export function deleteInvestorReportingSnapshot(id) {
  return axios.delete(`${apiRoot}/investor-reporting/update/${id}/`);
}

export function updateInvestorReportingSnapshotMeta(id, payload) {
  return axios.patch(`${apiRoot}/investor-reporting/update-meta/${id}/`, payload);
}

const manageRecipientsCb = (id, payload, action) => {
  return axios.post(`${apiRoot}/investor-reporting/${id}/recipients/`, {
    ...payload,
    action
  });
};

export function notifyRecipients(id, payload) {
  return manageRecipientsCb(id, payload, "notify");
}

export function removeRecipients(id, payload) {
  return manageRecipientsCb(id, payload, "remove");
}

export function addRecipients(id, payload) {
  return manageRecipientsCb(id, payload, "add");
}

export function setRecipients(id, payload) {
  return manageRecipientsCb(id, payload, "set");
}

export function getKpiComparison({ id, from, to }) {
  return axios.get(`${goApiRoot}/investor/comparison/kpi/${id}/${from}/${to}`);
}

export function getSharedKpis() {
  return axios.get(`${goApiRoot}/investor/kpis`);
}

export function getIsReportNameFree(query) {
  return axios.get(`${goApiRoot}/investor/check?${QS(query)}`);
}

export function updateReportData(reportId, data) {
  return axios.post(`${apiRoot}/investor-reporting/${reportId}/data/`, { data });
}

export function getSectionVariants() {
  return axios.get(`${apiRoot}/investor-reporting/section-variants/`);
}

export function getSchemaTemplates() {
  return axios.get(`${apiRoot}/investor-reporting/schema-templates/`);
}

export function postSchemaTemplate(payload) {
  return axios.post(`${apiRoot}/investor-reporting/schema-template/`, payload);
}

export function exportExcelFile(id) {
  return axios.get(`${apiRoot}/investor-reporting/export-excel/${id}/`, {
    responseType: "blob"
  });
}

export function exportPdfFile(id, token = false, theme = null) {
  // set theme fallback
  if (theme == null) {
    theme = "dark";
  }

  // override the headers with the refresh token
  const headers = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    : {};
  return axios.get(
    `${goApiRoot}/investor/export-pdf/${id}?theme=${theme}`,
    {
      responseType: "blob"
    },
    headers
  );
}

// attachments
export function uploadFile(id, file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(`${warehouseRoot}/investor/${id}/file`, formData);
}

export function listFiles(id) {
  return axios.get(`${warehouseRoot}/investor/${id}/files`);
}

export function getFile(id, fileName) {
  return axios.get(`${warehouseRoot}/investor/${id}/file?${QS({ file_name: fileName })}`, {
    responseType: "blob"
  });
}

export function deleteFile(id, fileName) {
  return axios.delete(`${warehouseRoot}/investor/${id}/file?${QS({ file_name: fileName })}`);
}

export function deleteRelatedFiles(id) {
  return axios.delete(`${warehouseRoot}/investor/${id}/files`);
}
