const initialState = {
  notifications: []
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} notification type:
   * {
   *  id: string // unique id
   *  variant: string // variant, see https://bootstrap-vue.org/docs/components/alert
   *  message: string // text to be displayed
   *  unique: boolean // if set to true the message will only appear once
   * }
   */
  addNotification(state, notification) {
    if (notification.unique) {
      if (state.notifications.findIndex(n => notification.id === n.id) === -1) {
        state.notifications.push(notification);
      }
    } else {
      state.notifications.push(notification);
    }
  },
  removeNotification(state, id) {
    state.notifications.splice(state.notifications.findIndex(n => n.id === id, 1));
  }
};

const actions = {};

const getters = {
  notifications: state => state.notifications
};

export const notificationsModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
