import axios from "axios";
import { apiRoot } from "@/preferences";

export function downloadTemplate(type, query) {
  axios({
    url: `${apiRoot}/kpi/upload/${type}/template/?${new URLSearchParams(query)}`,
    method: "GET",
    responseType: "blob"
  }).then(response => {
    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    var fileLink = document.createElement("a");

    fileLink.href = fileURL;
    const now = new Date();
    const filename =
      getDateString(now) + "_valueworks_" + type + "_" + query.from + "-" + query.to + ".xlsx";
    fileLink.setAttribute("download", filename);
    document.body.appendChild(fileLink);

    fileLink.click();
  });
}

export function uploadHistory(type, query) {
  return axios.get(`${apiRoot}/kpi/upload/${type}/history/?${new URLSearchParams(query)}`);
}

export function uploadExcel(type, file, onProgress) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("filename", file.name);
  return axios.post(`${apiRoot}/kpi/upload/${type}/`, formData, onProgress);
}

export function getDateString(d) {
  return d.getFullYear() + ("0" + (d.getMonth() + 1)).slice(-2) + ("0" + d.getDate()).slice(-2);
}

export function getUploadableCategories() {
  return axios.get(`${apiRoot}/kpi/upload/categories/`);
}

export function uploadExcelCheck(type, file) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("filename", file.name);
  return axios.post(`${apiRoot}/kpi/upload/check/${type}/`, formData);
}
