import dayjs from "dayjs";
import { daterangeAppSettingsKey, ignoreSettingsSaveKey } from "./util";
import { updateUserAppSettings } from "@/services/settingsService.js";

const datenow = new Date();
const yearnow = datenow.getFullYear();
const monthnow = datenow.getMonth();
const quarternow = Math.floor((monthnow + 3) / 3);

const defaultDateFrame = {
  startDate: undefined,
  endDate: isoStr(yearnow, monthnow + 1)
};
const defaultQuarter = {
  startDate: isoStr(yearnow, quarternow * 3 - 2),
  endDate: isoStr(yearnow, quarternow * 3)
};
const defaultYear = {
  startDate: isoStr(yearnow, 1),
  endDate: isoStr(yearnow, 12)
};

const initialState = {
  nowMonthlyDate: defaultDateFrame,
  nowQuarterDate: defaultQuarter,
  planningDate: defaultYear,
  reportingDate: defaultDateFrame,
  reportingDateRange: 1,
  okrDate: defaultQuarter,
  okrYear: yearnow,
  okrYearDate: defaultYear,
  inputDate: defaultDateFrame,
  kpiDetailDate: defaultDateFrame,
  kpiDetailDateRange: 1,
  datePickerRangeSelected: "month"
};

const mutations = {
  updatePlanningDate(state, date) {
    state.planningDate = date;
  },
  updateReportingDate(state, date) {
    state.reportingDate = date;
    state.reportingDateRange = calculateRange(date.startDate, date.endDate);
  },
  updateOkrDate(state, date) {
    state.okrDate = date;
  },
  changeOkrDateYear(state, year) {
    // Change only the year, but keep the month for OKR startDate and endDate
    let startMonth = new Date(state.okrDate.startDate).getMonth() + 1;
    let endMonth = new Date(state.okrDate.endDate).getMonth() + 1;
    state.okrDate = {
      startDate: isoStr(year, startMonth),
      endDate: isoStr(year, endMonth)
    };
  },
  updateOkrYear(state, year) {
    state.okrYear = year;
    state.okrYearDate = {
      startDate: isoStr(year, 1),
      endDate: isoStr(year, 12)
    };
  },
  updateInputDate(state, date) {
    state.inputDate = date;
  },
  updateKpiDetailDate(state, date) {
    state.kpiDetailDate = date;
    state.kpiDetailDateRange = calculateRange(date.startDate, date.endDate);
  },
  updateDatePickerRangeSelected(state, range) {
    state.datePickerRangeSelected = range;
  }
};

const actions = {
  setPlanningDate(context, date) {
    context.commit("updatePlanningDate", date);
  },
  setReportingDate(context, { date, saveAppSettings }) {
    const oldReportingDateRange = context.state.reportingDateRange;
    context.commit("updateReportingDate", date);
    const newReportingDateRange = context.state.reportingDateRange;

    // Change filterSettings trend duration
    if (oldReportingDateRange !== newReportingDateRange) {
      const payload = {
        trendDuration: newReportingDateRange
      };
      if (!saveAppSettings) {
        payload[ignoreSettingsSaveKey] = null;
      }
      context.commit("setFilterSettings", payload);
    }
  },
  setOkrDate(context, date) {
    context.commit("updateOkrDate", date);
    context.commit("updateOkrYear", new Date(date.endDate).getFullYear());
  },
  setOkrYear(context, year) {
    context.commit("updateOkrYear", year);
    context.commit("changeOkrDateYear", year);
  },
  setInputDate(context, date) {
    context.commit("updateInputDate", date);
  },
  setKpiDetailDate(context, date) {
    context.commit("updateKpiDetailDate", date);
  },
  setDatePickerRangeSelected(context, payload) {
    context.commit("updateDatePickerRangeSelected", payload);
  },
  async saveDatePickerAppSettings(context) {
    updateUserAppSettings(daterangeAppSettingsKey, {
      settings: { range: context.state.datePickerRangeSelected }
    });
  }
};

const getters = {
  nowMonthlyDate: state => state.nowMonthlyDate,
  nowQuarterDate: state => state.nowQuarterDate,
  planningDate: state => state.planningDate,
  reportingDate: state => state.reportingDate,
  reportingDateRange: state => state.reportingDateRange,
  okrDate: state => state.okrDate,
  okrYear: state => state.okrYear,
  okrYearDate: state => state.okrYearDate,
  inputDate: state => state.inputDate,
  kpiDetailDate: state => state.kpiDetailDate,
  kpiDetailDateRange: state => state.kpiDetailDateRange,
  datePickerRangeSelected: state => state.datePickerRangeSelected
};

export const dateModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};

/** Get month number string with leading zero
 * @param {Number} month Month number integer
 * @returns {String} Month number string with leading zero
 */
function monthStr(month) {
  return ("0" + month.toString()).slice(-2);
}

/**
 * Create ISO date string for the first day of the month
 * @param {Number} year Year number integer
 * @param {Number} month Month number integer
 * @returns
 */
function isoStr(year, month) {
  return `${year}-${monthStr(month)}-01`;
}

/**
 * Calculate monthly range between two dates
 * @param {Date} startDate Range start date
 * @param {Date} endDate Range end date
 * @returns Number of months in range
 */
function calculateRange(startDate, endDate) {
  if (startDate === undefined) {
    // If only endDate is specified the default behaviour is to retrieve 1 month
    return 1;
  }
  startDate = dayjs(startDate);
  endDate = dayjs(endDate);
  return endDate.diff(startDate, "month", true) + 1;
}
