import { stateInitial, stateLoading, stateLoaded, stateError } from "./util";
import { uploadExcel } from "@/services/uploadService";
import { uploadHistory } from "../services/uploadService";

const initialState = {
  planningUploadState: stateInitial,
  planningUploads: {
    count: 0,
    offset: 0,
    limt: 0,
    yearsAvailable: [],
    results: [],
    year: 0
  },
  reportingUploadState: stateInitial,
  reportingUploads: {
    count: 0,
    offset: 0,
    limit: 0,
    yearsAvailable: [],
    results: [],
    year: 0
  },
  forecastingUploadState: stateInitial,
  forecastingUploads: {
    count: 0,
    offset: 0,
    limit: 0,
    yearsAvailable: [],
    results: [],
    year: 0
  }
};

const mutations = {
  planningUploadsStateLoading(state) {
    state.planningUploadState = stateLoading;
  },
  planningUploadsStateLoaded(state, response) {
    state.planningUploads = response;
    state.planningUploadState = stateLoaded;
  },
  planningUploadsStateError(state, error) {
    state.planningUploadState = stateError;
    state.planningUploadState.errorMessage = error;
  },
  reportingUploadsStateLoading(state) {
    state.reportingUploadState = stateLoading;
  },
  reportingUploadsStateLoaded(state, response) {
    state.reportingUploads = response;
    state.reportingUploadState = stateLoaded;
  },
  reportingUploadsStateError(state, error) {
    state.reportingUploadState = stateError;
    state.reportingUploadState.errorMessage = error;
  },
  forecastingUploadsStateLoading(state) {
    state.forecastingUploadState = stateLoading;
  },
  forecastingUploadsStateLoaded(state, response) {
    state.forecastingUploads = response;
    state.forecastingUploadState = stateLoaded;
  },
  forecastingUploadsStateError(state, error) {
    state.forecastingUploadState = stateError;
    state.forecastingUploadState.errorMessage = error;
  }
};

const actions = {
  planningUploadsLoad(context, query) {
    context.commit("planningUploadsStateLoading");
    uploadHistory("planning", query).then(
      response => {
        context.commit("planningUploadsStateLoaded", {
          ...response.data,
          offset: query.offset,
          limit: query.limit
        });
      },
      error => {
        context.commit("planningUploadsStateError", error);
      }
    );
  },
  reportingUploadsLoad(context, query) {
    context.commit("reportingUploadsStateLoading");
    uploadHistory("reporting", query).then(
      response => {
        context.commit("reportingUploadsStateLoaded", {
          ...response.data,
          offset: query.offset,
          limit: query.limit
        });
      },
      error => {
        context.commit("reportingUploadsStateError", error);
      }
    );
  },
  forecastingUploadsLoad(context, query) {
    context.commit("forecastingUploadsStateLoading");
    uploadHistory("forecasting", query).then(
      response => {
        context.commit("forecastingUploadsStateLoaded", {
          ...response.data,
          offset: query.offset,
          limit: query.limit
        });
      },
      error => {
        context.commit("forecastingUploadsStateError", error);
      }
    );
  },
  uploadPlanning(context, options) {
    context.commit("planningUploadsStateLoading");
    uploadExcel("planning", options.file, options.callback).then(
      () => {
        context.dispatch("planningUploadsLoad", {
          offset: context.state.planningUploads.offset,
          limit: context.state.planningUploads.limit,
          year: context.state.planningUploads.year
        });
      },
      error => {
        context.commit(
          "planningUploadsStateError",
          error.response ? error.response.data.error : error
        );
      }
    );
  },
  uploadReporting(context, options) {
    context.commit("reportingUploadsStateLoading");
    uploadExcel("reporting", options.file, options.callback).then(
      () => {
        context.dispatch("reportingUploadsLoad", {
          offset: context.state.reportingUploads.offset,
          limit: context.state.reportingUploads.limit,
          year: context.state.planningUploads.year
        });
      },
      error => {
        context.commit(
          "reportingUploadsStateError",
          error.response ? error.response.data.error : error
        );
      }
    );
  },
  uploadForecasting(context, options) {
    context.commit("forecastingUploadsStateLoading");
    uploadExcel("forecasting", options.file, options.callback).then(
      () => {
        context.dispatch("forecastingUploadsLoad", {
          offset: context.state.forecastingUploads.offset,
          limit: context.state.forecastingUploads.limit,
          year: context.state.planningUploads.year
        });
      },
      error => {
        context.commit(
          "forecastingUploadsStateError",
          error.response ? error.response.data.error : error
        );
      }
    );
  }
};

const getters = {
  reportingUploadState: state => state.reportingUploadState,
  reportingUploads: state => state.reportingUploads,
  planningUploadState: state => state.planningUploadState,
  planningUploads: state => state.planningUploads,
  forecastingUploadState: state => state.forecastingUploadState,
  forecastingUploads: state => state.forecastingUploads,
  yearsUploaded: state => [
    ...new Set([...state.planningUploads.yearsAvailable, ...state.reportingUploads.yearsAvailable])
  ]
};

export const uploadModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
