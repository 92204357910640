import { getCompanyInfo, getGptTokensUsageWarning } from "@/services/chatgptSettingsService.js";
import {
  stateError,
  stateInitial,
  stateLoaded,
  stateLoading,
  executeTryCatchCallbacks
} from "./util";
import store from "@/store";

const initialState = {
  canViewChatgptFunctionalitiesLoadingState: stateInitial,
  canViewChatgptFunctionalities: false,
  chatgptTokensUsageMeta: {
    warning: false,
    warningThreshold: 0,
    date: ""
  }
};

const mutations = {
  setCanViewChatgptFunctionalities(state, canViewChatgptFunctionalities) {
    state.canViewChatgptFunctionalities = canViewChatgptFunctionalities;
  },
  setGetCompanyInfoLoadingState(state, loadingState) {
    state.canViewChatgptFunctionalitiesLoadingState = loadingState;
  },
  setChatgptTokensUsageMeta(state, usageMeta) {
    state.chatgptTokensUsageMeta = usageMeta;
  }
};

// Callback to set the error state
const setErrorState = error => {
  store.commit("setGetCompanyInfoLoadingState", {
    ...stateError,
    errorMessage: error
  });
};

const actions = {
  async loadCompanyInfo(context) {
    context.commit("setGetCompanyInfoLoadingState", stateLoading);
    await executeTryCatchCallbacks(async () => {
      const response = await getCompanyInfo();
      context.commit(
        "setCanViewChatgptFunctionalities",
        response.data.canViewChatgptFunctionalities
      );
    }, setErrorState);

    context.commit("setGetCompanyInfoLoadingState", stateLoaded);
  },
  updateGptUsageMeta(context, payload) {
    context.commit("setChatgptTokensUsageMeta", {
      warning: payload.warning,
      warningThreshold: payload.warning_threshold,
      date: payload.date
    });
  },
  async loadGptUsageMeta(context) {
    await executeTryCatchCallbacks(
      async () => {
        const response = await getGptTokensUsageWarning();
        context.dispatch("updateGptUsageMeta", response.data);
      },
      () => {}
    );
  }
};

const getters = {
  canViewChatgptFunctionalities: state => state.canViewChatgptFunctionalities,
  chatgptTokensUsageMeta: state => state.chatgptTokensUsageMeta
};

export const chatgptSettingsModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
