import Vue from "vue";
import { stateInitial } from "./util";
import { stateLoading, stateLoaded, stateError } from "./util";

import {
  addComment,
  deleteComment,
  deleteKpiAction,
  getComments,
  getKpiActions,
  patchKpiAction
} from "../services/kpiActionService";

const initialState = {
  kpiActionState: stateInitial,
  kpiActions: [],
  actionComments: {},
  user: null,
  kpi: null,
  offset: null,
  status: null
};

const mutations = {
  kpiActionsLoading(state, query) {
    state.kpiActionState = stateLoading;
    if (query) {
      state.user = query.user;
      state.kpi = query.kpi;
      state.page = query.page;
      state.status = query.status;
    }
  },
  kpiActionsLoaded(state, kpiActions) {
    state.kpiActionState = stateLoaded;
    state.kpiActions = kpiActions;
  },
  kpiActionDeleted(state, id) {
    const index = state.kpiActions.findIndex(action => action.id === id);
    if (index > -1) {
      state.kpiActions.splice(index, 1);
    }
    state.kpiActionState = stateLoaded;
  },
  kpiActionUpdated(state, payload) {
    const index = state.kpiActions.findIndex(action => action.id === payload.id);
    if (index > -1) {
      state.kpiActions.splice(index, 1, {
        ...state.kpiActions[index],
        ...payload.patch
      });
    }
    state.kpiActionState = stateLoaded;
  },
  kpiActionsError(state, error) {
    state.kpiActionState = stateError;
    state.error = error;
  },
  actionCommentsLoading(state, id) {
    Vue.set(state.actionComments, id, stateLoading);
  },
  actionCommentsLoaded(state, payload) {
    Vue.set(state.actionComments, payload.id, stateLoaded);
    state.actionComments[payload.id]["result"] = payload.result.comments;
    const action = state.kpiActions.find(a => a.id === payload.id);
    if (action) {
      action.commentCount = payload.result.comments.length;
    }
  },
  actionCommentsError(state, payload) {
    Vue.set(state.actionComments, payload.id, stateError);
    state.actionComments[payload.id]["error"] = payload.error;
  }
};

const actions = {
  loadKpiActions(context, query) {
    context.commit("kpiActionsLoading", query);
    getKpiActions(query).then(
      response => {
        context.commit("kpiActionsLoaded", response.data.results);
      },
      error => {
        context.commit("kpiActionsError", error);
      }
    );
  },
  deleteAction(context, id) {
    context.commit("kpiActionsLoading");
    deleteKpiAction(id).then(
      () => {
        context.commit("kpiActionDeleted", id);
      },
      error => {
        context.commit("kpiActionsError", error);
      }
    );
  },
  updateAction(context, payload) {
    context.commit("kpiActionsLoading");
    let request = payload.patch;
    if (payload.patch.assignedUser) {
      // NOTE: if the assignedUser is updated we only add the id to the actual request
      request = {
        ...payload.patch,
        assignedUser: payload.patch.assignedUser.id
      };
    }
    patchKpiAction(payload.id, request).then(
      () => {
        context.commit("kpiActionUpdated", payload);
      },
      error => {
        context.commit("kpiActionsError", error);
      }
    );
  },

  loadActionComments(context, actionId) {
    context.commit("actionCommentsLoading", actionId);
    getComments(actionId).then(
      response =>
        context.commit("actionCommentsLoaded", {
          id: actionId,
          result: response.data
        }),
      error => context.commit("actionCommentsError", { id: actionId, error })
    );
  },
  addActionComment(context, payload) {
    context.commit("actionCommentsLoading", payload.id);
    addComment(payload.id, payload.text).then(
      () => {
        context.dispatch("loadActionComments", payload.id);
      },
      error => context.commit("actionCommentsError", { id: payload.id, error })
    );
  },
  deleteActionComment(context, payload) {
    context.commit("actionCommentsLoading", payload.id);
    deleteComment(payload.actionId, payload.commentId).then(
      () => {
        context.dispatch("loadActionComments", payload.id);
      },
      error => context.commit("actionCommentsError", { id: payload.id, error })
    );
  }
};

const getters = {
  kpiActionLoadingState: state => state.kpiActionState,
  kpiActions: state => state.kpiActions,
  userid: state => state.userid,
  kpi: state => state.kpi,
  offset: state => state.offset,
  status: state => state.status,
  actionComments: state => state.actionComments
};

export const kpiActionsModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
