import axios from "axios";
import store from "@/store";
import { refreshToken } from "./authService";
import { secondsUntilNow, getExpFromTokenOrZero } from "@/store/authModule";
import { copilotRoot } from "@/preferences";

// TODO: exclude other base urls and registration endpoints, otherwise the token will leak
export const AuthInterceptor = async config => {
  const isRefreshRequest = config.url.endsWith("auth/token/refresh/");
  const isCopilotRequest = config.url.startsWith(copilotRoot);

  // If token isn't set or the request is refresh token, return the request without adding headers
  if (!store.getters.token || isRefreshRequest || isCopilotRequest) {
    return config;
  }

  // Check if access token is expired, by checking the data directly from the token stored in vuex
  const accessTokenExpire = getExpFromTokenOrZero(store.getters.token.access);
  const isAccessExpired = secondsUntilNow(accessTokenExpire) <= 0;

  // if the token is expired, we need to refresh the access token, achieved by sending a refresh token request
  // then we need to update the access token in the store
  if (isAccessExpired) {
    // Multiple requests could be sent at the same time, we only need to refresh the token once
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        requestQueue.push({ resolve, reject });
      }).then(token => {
        config.headers.Authorization = "Bearer " + token;

        return config;
      });
    }

    isRefreshing = true;
    // Send refresh token request
    const response = await refreshToken(store.getters.token.refresh);
    // Update refresh and access token in the store
    store.dispatch("tokenRefresh", response.data);
    // Process requests in the queue
    processQueue(null, response.data.access);
    isRefreshing = false;
  }
  config.headers.Authorization = "Bearer " + store.getters.token.access;

  return config;
};

let isRefreshing = false;
let requestQueue = [];

export const AuthErrorInterceptor = error => {
  if (axios.isCancel(error)) return Promise.reject(error);

  const originalRequest = error.config;
  const isLoginRequest = originalRequest.url.endsWith("auth/token/");
  const isLoginCodeRequest = originalRequest.url.endsWith("auth/otp/validate/");
  const isRefreshRequest = originalRequest.url.endsWith("auth/token/refresh/");
  const isCopilotRequest = originalRequest.url.startsWith(copilotRoot);
  if (isRefreshRequest) {
    store.dispatch("logout");
  }

  if (
    error.response.status === 401 &&
    !originalRequest._retry &&
    !isLoginRequest &&
    !isLoginCodeRequest &&
    !isCopilotRequest
  ) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        requestQueue.push({ resolve, reject });
      })
        .then(token => {
          originalRequest.headers.Authorization = "Bearer " + token;
          return axios(originalRequest);
        })
        .catch(err => {
          return err;
        });
    }

    if (store.getters.token === undefined) {
      store.dispatch("logout");
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(function (resolve, reject) {
      refreshToken(store.getters.token.refresh)
        .then(({ data }) => {
          store.dispatch("tokenRefresh", data);
          originalRequest.headers.Authorization = "Bearer " + data.access;
          processQueue(null, data.access);
          resolve(axios(originalRequest));
        })
        .catch(err => {
          processQueue(err, null);
          reject(err);
          store.dispatch("logout");
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }
  return Promise.reject(error);
};

const processQueue = (error, token) => {
  requestQueue.forEach(prom => {
    if (error !== null || token === null) {
      prom.reject();
    } else {
      prom.resolve(token);
    }
  });
  requestQueue = [];
};
