import axios from "axios";
import { apiRoot, goApiRoot } from "@/preferences";

export function login(email, password) {
  console.log(`${goApiRoot}/auth/token/`);
  return axios.post(`${goApiRoot}/auth/token/`, { email, password });
}

export function loginCode(ephemeral_token, code) {
  return axios.post(
    `${goApiRoot}/auth/otp/validate`,
    { passcode: code },
    {
      headers: {
        Authorization: `Bearer ${ephemeral_token}`
      }
    }
  );
}

export function refreshToken(token) {
  return axios.post(`${apiRoot}/auth/token/refresh/`, { refresh: token });
}

export function createUser(data) {
  return axios.post(`${apiRoot}/auth/users/`, data);
}

export function changePasswordCurrentUser(data) {
  return axios.post(`${apiRoot}/auth/users/set_password/`, data);
}

export function resetPassword(data) {
  return axios.post(`${apiRoot}/auth/users/reset_password/`, data);
}

export function resetPasswordConfirm(data) {
  return axios.post(`${apiRoot}/auth/users/reset_password_confirm/`, data);
}

export function getCurrentUser() {
  return axios.get(`${apiRoot}/auth/users/me/`);
}

export function getUsers() {
  return axios.get(`${apiRoot}/vw/auth/users/`);
}

export function getPermissionGroups() {
  return axios.get(`${apiRoot}/permissions/groups/`);
}

export function updateUser(id, data) {
  return axios.patch(`${apiRoot}/auth/users/${id}/`, data);
}

export function deleteUser(id) {
  return axios.delete(`${apiRoot}/auth/users/${id}/`);
}

export function updateUserPermissions(id, data) {
  return axios.patch(`${apiRoot}/permissions/users/${id}/`, data);
}

export function downloadUsersTemplate() {
  axios({
    url: `${apiRoot}/auth/users/template/download/`,
    method: "GET",
    responseType: "blob"
  }).then(response => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    const filename = "valueworks_" + "users_management" + ".xlsx";
    fileLink.setAttribute("download", filename);
    document.body.appendChild(fileLink);

    fileLink.click();
  });
}

export function uploadUsersTemplate(file) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("filename", file.name);
  return axios.post(`${apiRoot}/auth/users/template/upload/`, formData);
}

export function getSSOConfig() {
  return axios.get(`${apiRoot}/sso/config/`);
}

export function unlinkSSOAccount(userId) {
  return axios.patch(`${apiRoot}/sso/link/${userId}/`, {
    sso_provider: null,
    sso_id: null
  });
}
