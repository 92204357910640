/**
 * Helper function to remove boilerplate of try-catch block.
 * Every callback argument can be async
 * @param {Function} beforeCb execute before try-catch block
 * @param {Function} tryCb execute inside try
 * @param {Function} onCatchCb execute on error catch, should accept error as the first argument
 * @param {Function} afterCb execute after the try-catch block
 */
export async function tryExec(beforeCb, tryCb, onCatchCb, afterCb) {
  if (beforeCb) await beforeCb();

  try {
    if (tryCb) await tryCb();
  } catch (e) {
    if (onCatchCb) await onCatchCb(e);
  }

  if (afterCb) await afterCb();
}

/**
 * Default callback to handle errors for a context
 * @param {Object} ctx `this` reference should contain: `errorMessage`
 * @param {Object} error error object
 */
function _defaultOnCatchCb(ctx, error) {
  const maxErrorLengthChars = 250;

  if (+process.env.VUE_APP_DEBUG) {
    console.error(error);
  }

  let responseMessage = error.response?.data || error;
  responseMessage = responseMessage?.detail ?? `${responseMessage}`;
  responseMessage =
    responseMessage.length > maxErrorLengthChars
      ? responseMessage.substring(0, maxErrorLengthChars) + "... (check the console)"
      : responseMessage;
  ctx.errorMessage = responseMessage;
}

/**
 * Helper function with predefined state to execute `tryExec`
 * @param {Object} ctx `this` reference should contain: `loading`, `parseError()` or `errorMessage`
 * @param {Function} tryCb execute inside try
 * @param {Function} afterCb execute after the try-catch block
 */
export async function tryExecCtx(ctx, tryCb, afterCb) {
  await tryExec(
    () => {
      ctx.loading = true;
      ctx.errorMessage = "";
    },
    tryCb,
    ctx.parseError || (error => _defaultOnCatchCb(ctx, error)),
    async () => {
      ctx.loading = false;
      afterCb && (await afterCb());
    }
  );
}
