import axios from "axios";
import { apiRoot } from "../preferences";

export function getKpiActions(query) {
  return axios.get(`${apiRoot}/actions/?${new URLSearchParams(query)}`);
}

export function deleteKpiAction(id) {
  return axios.delete(`${apiRoot}/actions/${id}/`);
}

export function patchKpiAction(id, patch) {
  return axios.patch(`${apiRoot}/actions/${id}/`, patch);
}

export function getComments(actionId) {
  return axios.get(`${apiRoot}/actions/${actionId}/comments/`);
}

export function addComment(actionId, text) {
  return axios.post(`${apiRoot}/actions/${actionId}/comments/`, { text });
}

export function deleteComment(actionId, commentId) {
  return axios.delete(
    `${apiRoot}/actions/${actionId}/comments/?${new URLSearchParams({
      id: commentId
    })}`
  );
}
