import i18n from "@/translations";
import { executeAsync } from "@/store/util";
import { sanitize as _ } from "dompurify";

export function objectToString(inputObject) {
  if (typeof inputObject === "string") {
    return inputObject;
  } else if (Array.isArray(inputObject)) {
    return Object.entries(inputObject)
      .map(([key, value]) => `${i18n.t(key)}: ${value.join(" ")}`)
      .join(" ");
  } else {
    return Object.keys(inputObject)
      .map(
        key =>
          `${i18n.t(key)}:  ${
            Array.isArray(inputObject[key]) ? inputObject[key].join(" ") : inputObject[key]
          }`
      )
      .join(" ");
  }
}

/**
 * Captialize the first letter of a word
 * @param {string} word           The word to capitalize its first letter, e.g., hello
 * @returns                       String with capitalized first letter, e.g., Hello
 */
export function capitalizeFirstLetter(word) {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : "";
}

export const UtilMixin = {
  methods: {
    escapeHtml(unsafe) {
      return unsafe.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    },
    unescapeHtml(unsafe) {
      return unsafe.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    },
    sanitize(dirty) {
      // allow target="_blank"
      return _(dirty, { ADD_ATTR: ["target"] });
    },
    objectToString(inputObject) {
      return objectToString(inputObject);
    },
    capitalizeFirstLetter(word) {
      return capitalizeFirstLetter(word);
    },
    getOrNull(value) {
      // Returns the passed value, or null if the value is undefined
      return value === undefined ? null : value;
    },
    /**
     * List of integers from `start` to `end` including both limits
     * @param {number} start range start
     * @param {number} end range end
     * @returns list of integers from `start` to `end` including both limits
     */
    range(start, end) {
      let result = [];
      for (let index = start; index <= end; index++) {
        result.push(index);
      }

      return result;
    },
    /**
     * Wrapper for the `executeAsync` from util.js
     */
    executeAsync(callback, delay, shouldSparse) {
      return executeAsync(callback, delay, shouldSparse);
    },
    /**
     * Get string date from month and year
     * @param {number} month         The month, e.g., 3
     * @param {number} year          The year, e.g., 2022
     * @returns {string}             The date starting from the first day of the month, e.g., 2022-04-01
     */
    dateFromMonthAndYear(month, year) {
      const oneBasedMonth = month + 1;
      const monthString =
        oneBasedMonth < 10 ? "0" + oneBasedMonth.toString() : oneBasedMonth.toString();
      return `${year}-${monthString}-01`;
    },
    /**
     * Retrieve year from string date
     * @param {string} date           The date, e.g., 2022-09-01
     * @returns {number}              The year, e.g., 2022
     */
    getYearFromStringDate(date) {
      return new Date(date).getFullYear();
    },
    /**
     * Identify users that have been mentioned in a comment
     * @param {string} text           The text containing the mentioned users, e.g., Hello johndoe@gmail.com xxxx janesmith@valueworks.ai
     * @returns {object}              An array-like object of the mentioned emails, e.g., ['johndoe@gmail.com', 'janesmith@valueworks.ai']
     */
    identifyMentionedUsers(text) {
      const mentiondUsers = text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
      if (mentiondUsers === null) {
        return [];
      }
      return mentiondUsers;
    },
    /**
     * Function to filter an array to keep only the elements that are keys of a dictionary object
     * @param {object} object         An object containing key values pairs, e.g., {'a':1, 'b':2, 'c':3}
     * @param {object} array          An array-like object, e.g., ['a', 'b', 'd']
     * @returns {object}              An array-like object, e.g., ['a', 'b']
     */
    filterExistingKeys(object, array) {
      const existingItems = [];
      for (let i = 0; i < array.length; i++) {
        if (this.objectContainsKey(object, array[i])) {
          existingItems.push(array[i]);
        }
      }
      return existingItems;
    },
    /**
     * Check if a key exists in object
     * @param {*} object                 object: Dictionary like object
     * @param {*} key                    Number: key to check
     * @returns                          Boolean: true if the key exists, false otherwise
     */
    objectContainsKey(object, key) {
      return object[key] !== undefined;
    }
  }
};
